import * as React from 'react';
import {FunctionComponent} from 'react';
import StaticFile from "../../Types/Content/StaticFile";
import DomPurify from "dompurify"
import {useQuery} from "@apollo/react-hooks";
import {GET_STATIC_FILE} from "../../GraphQLQueries";
import LoadingOrErrorIndicator from "../LoadingOrErrorIndicator";
import {Navigate} from "react-router";
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";

/**
 * Gets and renders the static file based on the filename
 * @param props Props contains at least the name of the static file to render
 */
const StaticFileView: FunctionComponent<{ fileName: string }> = (props) => {
    const {
        loading,
        error,
        data
    } = useQuery<{ staticFile: StaticFile }>(GET_STATIC_FILE, {variables: {"fileName": props.fileName}})
    if (loading || error)
    {
        return <LoadingOrErrorIndicator loading={loading} error={error}/>
    }
    else if (data && data.staticFile !== null)
    {
        let content = DomPurify.sanitize(data.staticFile.content)
        let staticFile = data.staticFile;

        return <main>
            <title>{staticFile.name}</title>
            <p><b>Author: </b>{staticFile.author}</p>
            <p><b>Version: </b>{staticFile.version}</p>
            <span dangerouslySetInnerHTML={{__html: content}}/>
        </main>
    }
    else if (data && data.staticFile === null)
    {
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    }
    else
    {
        console.error("how did it have no loading,error or data?");

        //THIS SHOULD NEVER HAPPEN
        return <LoadingOrErrorIndicator loading={loading} error={error}/>
    }
};

export default StaticFileView