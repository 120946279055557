import * as React from 'react';
import {FunctionComponent} from 'react';
import {Navigate, useParams} from "react-router";
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";
import StoryView from "./StoryView";

const StoryPage: FunctionComponent = () => {
    let storyName: string | undefined;
    ({storyName} = useParams());

    if (storyName === undefined)
    {
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    }
    else
    {
        return <StoryView storyId={storyName}/>
    }
};
export default StoryPage