import {default as React, FunctionComponent} from "react";
import PassiveLoader from "./PassiveLoader";
import {GENERIC_ERROR_MESSAGE} from "../Constants";

/**
 * Renders either an error or a loading message based on props. Error messages always takes precedence.
 * Loading or Error messages are rendered if the prop evaluates to true. If both error and loading props are
 * falsy then it renders nothing.
 * @param props Props contains a value for loading and error. It also contains a optional flag if loading should be presented instantly
 *
 */
const LoadingOrErrorIndicator: FunctionComponent<{ loading: any, error: any, isInstantLoading?: boolean }> = (props) => {
    if (props.error)
    {
        return <div> {GENERIC_ERROR_MESSAGE} </div>
    }
    else if (props.loading)
    {
        return <PassiveLoader isInstantLoading={props.isInstantLoading}/>
    }
    else
    {
        return null;
    }
};
export default LoadingOrErrorIndicator