import React, {FunctionComponent, useState} from 'react';
import Post from "../../Types/Content/Post";
import {Box, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import HTMLRenderer from "./HTMLRenderer";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {POST_GET_NEXT_POSTS, POST_INITIAL_QUERY} from "../../GraphQLQueries";
import PassiveLoader from "../PassiveLoader";
import ErrorMessage from "../ErrorMessage";
import {Navigate} from "react-router";
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";
import Story from "../../Types/Content/Story";
import {reformatPostNameFromLink} from "../../Utils/LinkUtils";
import PostLinks from "./PostLinks";
import ContentBox from "./ContentBox";


export function getAdjacentPositions(currentPost: Post): number[]
{
    if (currentPost.position === 1)
    {

        return [1, 2]

    } else
    {

        return [currentPost.position - 1, currentPost.position, currentPost.position + 1]

    }

}

/**
 * Display a given post in the browser. The post's content is sanitized before being rendered
 * @param props should contain a post with at least, imageURL,heroImageBackgroundColour,content,altText
 */
const PostView: FunctionComponent<{ story: string, postName: string, isInstantLoading?: boolean }> = (props) =>
{

    let postName = reformatPostNameFromLink(props.postName);

    let [nextPost, setNextPost] = useState<Post | undefined>(undefined);
    let [previousPost, setPreviousPost] = useState<Post | undefined>(undefined);

    let story = props.story;
    let postID = story + "-" + postName;

    const [getAdjacentPosts] = useLazyQuery<{ story: Story }>(POST_GET_NEXT_POSTS,
        {
            onCompleted: newData =>
            {
                let adjacentPosts = newData.story.posts.page

                if (adjacentPosts.length === 3)
                {
                    setPreviousPost(adjacentPosts[0])
                    setNextPost(adjacentPosts[2])
                } else if (adjacentPosts.length === 2)
                {
                    if (adjacentPosts[0].id === postID)
                    {
                        setNextPost(adjacentPosts[1])
                    } else
                    {
                        setPreviousPost(adjacentPosts[0])
                    }
                    //implicit else there the length is 1 in which case this is the first and only post published
                    //any other result would be an error in the query and thus can be treated as no other post
                }
            }
        });

    const getInitialPost = useQuery<{ post: Post }>(POST_INITIAL_QUERY,
        {
            variables: {"postId": postID},onCompleted: (newData) =>
            {
                getAdjacentPosts({variables: {"storyId": story, "positions": getAdjacentPositions(newData.post)}}).catch((e) => console.log(e))
            }
        });
    const initialLoading = getInitialPost.loading;
    const initialError = getInitialPost.error;
    const initialData: { post: Post } | undefined = getInitialPost.data;

    const theme = useTheme();
    const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    let width;
    switch (true) {
        case isXLargeScreen:
            width = '65%';
            break;
        case isLargeScreen:
            width = '75%';
            break;
        case isMediumScreen:
            width = '85%';
            break;
        case isSmallScreen:
        default:
            width = '100%';
    }



    //main render section
    if (initialLoading)
    {
        return <PassiveLoader isInstantLoading={props.isInstantLoading}/>;
    } else if (initialError)
    {
        return <ErrorMessage isError={true}/>;
    } else if (initialData && initialData.post)
    {
        let post = initialData.post
        return <Box display="flex"
                    justifyContent="center"
                    alignItems="center">
            <Stack sx={{width:width}}>
            <Paper elevation={24} sx={{width:"100%"}}>
                <article style={{"width": "100%"}} aria-labelledby={post.name}>
                    <img style={{"width": "100%"}} src={post.imageURL} alt={post.altText}/>
                    <Box p={2}>
                        <ContentBox>
                            <Typography variant="h1">
                                {post.name}
                            </Typography>
                            <HTMLRenderer content={post.content}/>
                        </ContentBox>
                    </Box>
                </article>
            </Paper>
            <PostLinks story={story} nextPost={nextPost} previousPost={previousPost}/>
        </Stack>
        </Box>
    }
    //couldn't find an initial post, this is a 404 error
    else if (initialData && !initialData.post)
    {
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    } else
    {
        return <PassiveLoader isInstantLoading={props.isInstantLoading}/>;
    }
};

export default PostView