import * as React from 'react';
import {FunctionComponent} from 'react';
import {Navigate, useParams} from "react-router";
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";
import PostView from "./PostView";

/**
 * Primarily a wrapper around post list which gets the story and post name from the url
 * This decouples the post list from the router*
 * This component doesn't check if the url parameters are correct
 */
const PostPage: FunctionComponent = () => {
    let story: string | undefined;
    let postName: string | undefined;
    ({story, postName} = useParams());

    if (story === undefined || postName === undefined)
    {
        //this should never actually happen
        console.error("Invalid routing shouldn't route to this page without these params");
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    }
    else
    {
        return <PostView  key={`${story}-${postName}`}  story={story} postName={postName}/>
    }
};
export default PostPage