import React, {ReactNode} from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from "../Theme/Theme";

// Define a default style
const useStyles = makeStyles({
    root: {
        '& *': {
            padding: '10px',
        },
        '& img': {
            maxWidth: '80%',
            display: 'block',
            margin: '0 auto',
        },
        '& ol, & ul': {
            paddingLeft: '50px',
        },
        '& li': {
            padding: '0',
            '&::marker': {
                fontSize: '1em',
                [theme.breakpoints.up('xs')]: {
                    fontSize: '0.8em',
                },
                [theme.breakpoints.up('sm')]: {
                    fontSize: '1rem',
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '1.2em',
                },
            },
        },
    },
});

type ContentBoxProps = {
    children: ReactNode;
};

const ContentBox: React.FC<ContentBoxProps> = ({ children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {children}
        </Box>
    );
};

export default ContentBox;