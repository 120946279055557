import * as React from 'react';
import {FunctionComponent, ReactElement} from 'react';
import {useQuery} from "@apollo/react-hooks";
import Site from "../Types/Content/Site";
import {GET_SITEMAP} from "../GraphQLQueries";
import LoadingOrErrorIndicator from "./LoadingOrErrorIndicator";
import SiteMapNode from "../Types/Content/SiteMapNode";
import {Box, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import Link from '@mui/material/Link';

/**
 * renders a sitemap for a single node
 * @param node the single node to render
 */
export function renderSingleNode(node: SiteMapNode): ReactElement | undefined
{
    if (node.url)
    {
        return <Typography variant="h3">
            <Link underline="hover" href={node.url}>{node.name}</Link>
        </Typography>
    } else
    {
        return undefined;
    }
}

/**
 * renders a SiteMapNode as a React Element
 * This function recursively explores the children of each element creating
 * an element for each
 * @param node the root node to be explored
 * @param isTop determines if the component is the top component
 * @return A react element representing the node and all of it's children
 */
export function renderNode(node: SiteMapNode): ReactElement
{
    let headerElement: ReactElement;
    if (node.children && node.children.length > 1 && node.url)
    {
        headerElement = <Typography id={node.name} variant="h3">
            <Link underline="hover" id={node.name} href={node.url}>
                {node.name}
            </Link>
        </Typography>
    } else if (node.url)
    {
        headerElement = <Typography id={node.name}>
            <Link underline="hover" id={node.name} href={node.url}>{node.name}</Link>
        </Typography>
    } else
    {
        //it's the root node
        headerElement = <Typography variant="h3">{node.name}</Typography>
    }
    let key = node.url ? node.url + node.name : node.name;
    if (node.children && node.children.length > 0)
    {
        return <Box key={key}>
            {headerElement}
            <List>
                {node.children.map(c => renderNode(c))}
            </List>
        </Box>
    } else
    {
        return <ListItem key={key}>
            <ListItemText>
                {headerElement}
            </ListItemText>
        </ListItem>
    }
}

/**
 * Gets and renders the Site Map of the websites
 */
export const SiteMapView: FunctionComponent = () =>
{
    const {loading, error, data} = useQuery<{ site: Site }>(GET_SITEMAP);
    if (loading || error)
    {
        return <LoadingOrErrorIndicator loading={loading} error={error}/>
    } else if (data && data.site)
    {
        let formattedJSON = data.site.map
            .replace(/"Name":/g, "\"name\":")
            .replace(/"URL":/g, "\"url\":")
            .replace(/"Children":/g, "\"children\":");
        let rootNodes: SiteMapNode[] = JSON.parse(formattedJSON);
        //TO DO makes these invisible until tabbed instead
        let rootNodesWithChildren = rootNodes.filter((n => n.children && n.children.length > 0))
            .map((n, index) => <div
                key={index}>{renderSingleNode(new SiteMapNode("Skip to " + n.name, "#" + n.name, []))}</div>)
        let reactSiteMapElements = rootNodes.map((n, index) => n.children && n.children.length > 0
            ? <div key={index}>{renderNode(n)}</div>
            : <div key={index}>{renderSingleNode(n)}</div>);
        return <Stack padding={1}>
            <Typography variant="h1">
                Site Map
            </Typography>
            {[...rootNodesWithChildren, ...reactSiteMapElements]}
        </Stack>
    } else
    {
        console.error("how did it have no loading,error or data?");
        //THIS SHOULD NEVER HAPPEN
        return <LoadingOrErrorIndicator loading={loading} error={true}/>
    }
};

export default SiteMapView;