import React from 'react';
import './App.css';
import {ApolloProvider} from '@apollo/client';
import StoryGrid from "./Components/Content/StoryGrid";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import PostPage from "./Components/Content/PostPage";
import {PAGE_NOT_FOUND_ROUTE} from "./Constants"
import StaticFilePage from "./Components/StaticFile/StaticFilePage";
import SiteMapView from "./Components/SiteMapView";
import StoryPage from "./Components/Content/StoryPage";
import Client from "./GraphQLClient";
import {Box, ThemeProvider} from '@mui/material';
import theme from "./Components/Theme/Theme";
import CustomAppBar from "./Components/CustomAppBar";

/**
 * Handles routing in the app and structure of the app
 */
const App: React.FC = () => {

    return (
        <ThemeProvider theme={theme}>
            <Router>
                    <CustomAppBar/>
                <Box sx={{ height:"100%",width:"100%",paddingTop:"90px" }} id={"mainContent"}>
                    <ApolloProvider client={Client}>
                        <Routes>
                            <Route path={"/post/:storyName"} element={<StoryPage/>}/>
                            <Route path={"/post/:story/:postName"} element={<PostPage/>}/>
                            <Route path={"/static/:filename"} element={<StaticFilePage/>}/>
                            <Route path={"/sitemap"} element={<SiteMapView/>}/>
                            <Route path={"/"} element={<StoryGrid/>}/>
                            <Route path={PAGE_NOT_FOUND_ROUTE} element={<StoryGrid/>}/>
                            <Route path={"*"} element={<StoryGrid/>}/>
                        </Routes>
                    </ApolloProvider>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
