class SiteMapNode
{
    /**
     * Represents a node in a site map
     * @param name the name of the site node
     * @param url the url of the site node
     * @param children the children of the site node
     */
    constructor(public readonly name: string,
                public readonly url: string | null,
                public readonly children: SiteMapNode[])
    {
    }
}

export default SiteMapNode