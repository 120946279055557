import {Slide, useScrollTrigger} from "@mui/material";
import React from "react";



export function HideOnScroll(props: { children: React.ReactElement; })
{
    const { children } = props;

    // Set disableHysteresis to true to ignore the scroll direction hysteresis
    // Set threshold to a number (e.g., 100) to delay the trigger until that many pixels are scrolled
    const trigger = useScrollTrigger({
        disableHysteresis: false,
        threshold: 0,
    });

    // Wrap children with a div that has a fixed position
    const fixedChildren = React.cloneElement(children, {
        style: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1100, // The z-index of AppBar in MUI is 1100
            ...children.props.style,
        },
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {fixedChildren}
        </Slide>
    );
}
export default HideOnScroll