import * as React from 'react';
import {FunctionComponent} from 'react';
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";
import Story from "../../Types/Content/Story";
import {useQuery} from "@apollo/react-hooks";
import {GET_STORY} from "../../GraphQLQueries";
import LoadingOrErrorIndicator from "../LoadingOrErrorIndicator";
import {Navigate} from "react-router";
import {Paper, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ContentCardData} from "../../Types/UI/ContentCardData";
import {ContentGrid} from "./ContentGrid";
import {createPostLink} from "../../Utils/LinkUtils";

const StoryView: FunctionComponent<{ storyId: string }> = (props) => {
    const {loading, error, data} = useQuery<{ story: Story }>(GET_STORY, {variables: {"storyId": props.storyId}});
    const navigate = useNavigate();
    if (loading || error)
    {
        return <LoadingOrErrorIndicator loading={loading} error={error}/>
    }
    else if (data && data.story !== null)
    {
        let myStory = data.story;
        let contentCardDataList = myStory.posts.page.map(p => new ContentCardData(
            p.name,p.imageURL,p.excerpt
            ,p.altText,p.id,(c) => navigate(createPostLink(myStory.id,c.title))));
        //navigate("/post/" + myStory.id + "/" + c.key))
        return <Paper sx={{height:"100%",width:"100%"}}>
            <Stack>
                <Typography sx={{margin:0.5}} variant="h1">
                    {myStory.name}
                </Typography>
                <Typography sx={{margin:0.5}} variant="h2">
                    {myStory.description}
                </Typography>
                <ContentGrid ContentCardDataList={contentCardDataList}/>
            </Stack>
        </Paper>
    }
    else if (data && data.story === null)
    {
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    }
    else
    {
        return <LoadingOrErrorIndicator loading={loading} error={error}/>
    }
};
export default StoryView;