/**
 * The data required for a content card.
 */
export class ContentCardData
{
    /**
     * Creates a new content card data object
     * @param title the title of the card
     * @param image the image of the card
     * @param subtitle the subtitle of the card
     * @param alt the alt text of image for the card
     * @param key the key of the card (used for react)
     * @param onClick the on click event for the card, can be undefined
     */
    constructor(public readonly title: string,
                public readonly image: string,
                public readonly subtitle: string,
                public readonly alt: string,
                public readonly key: string,
                public readonly onClick: (c:ContentCardData) => void | undefined = () => {}) {

    }
}

