export function reformatPostNameForLink(postName: string): string
{
    return postName.replaceAll("-", "--").replaceAll(" ", "-");
}

export function createPostLink(storyId: string, postName:string): string
{
    return "/post/" + storyId + "/" + reformatPostNameForLink(postName);
}

export function createStoryLink(storyId: string): string
{
    return "/post/" + storyId;
}

export function reformatPostNameFromLink(postName: string): string
{
    //preserve - in name by converting to unlikely string
    postName = postName.replaceAll("--", "#!@");
    //convert - to space
    postName = postName.replaceAll("-", " ");
    //convert unlikely string to dash
    return postName.replaceAll("#!@", "-");
}