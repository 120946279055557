import {Grid} from "@mui/material";
import * as React from "react";
import {ContentCardData} from "../../Types/UI/ContentCardData";
import {ContentCard} from "./ContentCard";

export function ContentGrid(props:{ContentCardDataList: ContentCardData[]})
{
    const {ContentCardDataList} = props;
    const container_md = ContentCardDataList.length % 2 === 0 ? 8 : 12
    const container_lg = ContentCardDataList.length % 4 === 0 ? 16 : container_md
    return <Grid  container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md:container_md, lg:container_lg}}>
        {ContentCardDataList.map(c =>  <Grid item key={c.key} xs={4} sm={4} md={4}>
            <ContentCard  ContentCardData={c}/>
        </Grid>)}
    </Grid>
}