import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser';
import {domToReact} from 'html-react-parser'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DOMPurify from 'dompurify';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import {getOtherBodyElementTextSize} from "../Theme/Theme";
/**
 * Renders html on to the page replacing necessary core html with relevant react components.
 * @param content - The HTML string to be rendered
 * @returns A JSX element containing the sanitized and parsed content
 */
type HTMLRendererProps = {
    content: string;
};


const HTMLRenderer: React.FC<HTMLRendererProps> = ({ content }) => {

    const [customSyntaxFormatterStyle, setCustomSyntaxFormatterStyle]
        = useState({fontSize: getOtherBodyElementTextSize()});

    useEffect(() => {
        const handleResize = () => {
            setCustomSyntaxFormatterStyle({
                fontSize: getOtherBodyElementTextSize(),
            });
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const parseOptions = {
        replace: (domNode:any) => {
            if (domNode.name && domNode.name.match(/h[1-6]/)) {
                return (
                    <Typography
                        component={domNode.name}
                        variant={domNode.name}
                    >
                        {domNode.children[0].data}
                    </Typography>
                );
            }
            if (domNode.name && domNode.name === 'p') {
                return (
                    <Typography component="p" variant="body1">
                        {domNode.children[0].data}
                    </Typography>
                );
            }
            if (domNode.name && domNode.name === 'div') {
                return (
                    <Box>
                        {domToReact(domNode.children,parseOptions)}
                    </Box>
                );
            }
            if (domNode.name && domNode.name === "code")
            {
                if(domNode.children && domNode.children.length === 1 && domNode.children[0].data)
                {
                    const language = domNode.attribs.language || "fsharp"; // Default to "fsharp" if no language attribute is provided
                    return <SyntaxHighlighter language={language} style={docco} customStyle={customSyntaxFormatterStyle}>
                        {domNode.children[0].data}
                    </SyntaxHighlighter>
                }
            }
            if (domNode.name && domNode.name === 'ol') {
                return (
                    <ol>
                        {domToReact(domNode.children, parseOptions)}
                    </ol>
                );
            }
            if (domNode.name && domNode.name === 'ul') {
                return (
                    <ul>
                        {domToReact(domNode.children, parseOptions)}
                    </ul>
                );
            }
            if (domNode.name && domNode.name === 'li') {
                return (
                    <li>
                        <Typography component="span" variant="body1">
                            {domToReact(domNode.children, parseOptions)}
                        </Typography>
                    </li>
                );
            }
        },
    };
        const sanitizedContent = DOMPurify.sanitize(content)
    return <Box>{parse(sanitizedContent, parseOptions)}</Box>;
};

export default HTMLRenderer;

