import React, {FunctionComponent} from "react";
import {GENERIC_ERROR_MESSAGE} from "../Constants";

/**
 * Renders an error message based on props. Renders nothing if isError is false.
 * @param props Props contains a value for isError and errorMessage.
 * @constructor Creates a ErrorMessage component.
 */
const ErrorMessage: FunctionComponent<{ isError: boolean, message?: string }> = (props) => {
    if (props.isError)
    {
        return <section>{props.message || GENERIC_ERROR_MESSAGE}</section>;
    }
    return null;
}
export default ErrorMessage