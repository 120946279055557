import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import {ContentCardData} from "../../Types/UI/ContentCardData";

export function ContentCard(props: {ContentCardData: ContentCardData}) {
    const { title, image, subtitle, alt ,onClick} = props.ContentCardData;

    return (<Card raised sx={{margin:1}} onClick={() => onClick(props.ContentCardData)}>
                <CardMedia
                    component="img"
                image={image}
                alt={alt}
                />
                <CardContent>
                    <Typography variant="h4" component="div">
                    {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                    {subtitle}
                    </Typography>
                </CardContent>
            </Card>
);
}
