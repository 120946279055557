import React from 'react';
import {AppBar, Box, Button, Toolbar, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import {HideOnScroll} from "./HideOnScroll";

const CustomAppBar: React.FC = () => {
    const theme = useTheme();

    const appBarButtonSx = {
        color: theme.palette.appBarText, // This sets the text color to black
        fontSize: {
            xs: '0.8rem',
            sm: '1.1rem',
            md: '1.2rem',
            lg: '1.2rem',
        },
        '&:not(:last-child)': { // Add right margin to all buttons except the last one
            marginRight: {
                xs: 0.5,
                sm: 2,
                md: 4,
            },
        }
    };
    return (
        <HideOnScroll>
            <AppBar sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
                <Toolbar>
                    <Box display="flex" alignItems="center" sx={{ padding: '5px 0px' }}>
                        <img src={"/img/logo.svg"} alt="logo" style={{height: '80px', width: 'auto'}}/>
                    </Box>
                    <Box margin={"auto"}>
                        <Button sx={appBarButtonSx} component={Link} to={"/"}>Home</Button>
                        <Button sx={appBarButtonSx} component={Link} to={"/static/welcome"}>Welcome</Button>
                        <Button sx={appBarButtonSx} component={Link} to={"/sitemap"}>Site Map</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
}

export default CustomAppBar;
