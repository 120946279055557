import {gql} from "@apollo/client";

export const STORY_LISTING_QUERY = gql`    
    query StoryListing
    {        
        stories
        {
            id
            name
            description
            posts(pageSize:1)
            {
                page
                {
                  imageURL
                  altText                          
                }
            }        
        }
    }
 
 
 `;

export const POST_INITIAL_QUERY = gql`
query getInitialPost($postId:String!)
{
    post(id:$postId)
    {
        id
        name
        content
        altText
        imageURL
        position        
    }

}`;

export const POST_GET_NEXT_POSTS = gql`
query getNextPost($storyId:String!,$positions:[Int!]!)
{
    story(id:$storyId)
    {
     posts(positions:$positions)
     {
       page
       {
        id
        imageURL
        altText
        name
        position
       }        
     }    
    }
}`;

export const GET_SITEMAP = gql`
query getSiteMap
{
    site
    {
      map   
    }
}`;

export const GET_STATIC_FILE = gql`
query getStaticFile($fileName:String!)
{
    staticFile(name:$fileName)
    {
    
       name
       author
       content
       version
    
    }
    
}`;

export const GET_STORY = gql`query getStory($storyId:String!)
{
    story(id:$storyId)
    {
      name
      id
      description
     posts(pageSize:50)
     {
       page
       {
        id
        name        
        altText
        imageURL
        excerpt
        position
        story
        {
         id                  
        }
       }        
     
     }    
    
    }
}`;

