import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        appBarText?: string;
        // ... potentially other custom colors
    }
    // Extending palette options is necessary if you want to set these in `createTheme`
    interface PaletteOptions {
        appBarText?: string;
    }
}

// Define screen sizes
const EXTRA_SMALL_SCREEN = 375;
const SMALL_SCREEN = 600;
const MEDIUM_SCREEN = 960;
const LARGE_SCREEN = 1280;
const XL_SCREEN = 1920;

// Reused root curved values
const BODY_ROOT_SIZE = 1.2;
const BODY_CURVE_SIZE = 1.1;


export function createSizeCurve(rootSize: number, curve:number = 2)
{

    if(curve <= 1)
    {

        throw new Error("Curve must be at least 1 to produce a reasonable layout")

    }

    return {
        "default": `${(rootSize / (curve * 1.5)).toFixed(2)}rem`,
        "sm": `${(rootSize / curve).toFixed(2)}rem`,
        "md": `${rootSize.toFixed(2)}rem`
    }

}

export function sizeGenerator(rootSize: number, curve: number = 2, fontWeight: string | number = "normal") {
    let screenSizes = createSizeCurve(rootSize,curve)
    return {
        fontSize: screenSizes["default"],
        fontWeight: fontWeight || 'normal',
        [`@media (min-width:${SMALL_SCREEN}px)`]: {
            fontSize: screenSizes["sm"],
        },
        [`@media (min-width:${MEDIUM_SCREEN}px)`]: {
            fontSize: screenSizes["md"],
        },
    };
}

const theme = createTheme({
    palette: {
        appBarText: "black",
        primary: {
            main: '#9168AD', // Replace '#yourColor' with the color you want as the primary color
            light: '#9168AD', // Optional: you can also define light and dark variants
            dark: '#9168AD', // Optional: as well as a dark variant
            contrastText: 'white', // Optional: and the text color that would contrast well on your primary color
        },
        // ... other palette options
    },
    breakpoints: {
        values: {
            xs: EXTRA_SMALL_SCREEN,
            sm: SMALL_SCREEN,
            md: MEDIUM_SCREEN,
            lg: LARGE_SCREEN,
            xl: XL_SCREEN,
        },
    },
    typography: {
        h1: sizeGenerator(3, 2),
        h2: sizeGenerator(1, 1.2),
        h3: sizeGenerator(1,1.2),
        body1: sizeGenerator(BODY_ROOT_SIZE, BODY_CURVE_SIZE),
    },components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
    },
});

export function getOtherBodyElementTextSize()
{
    let textSize: string;
    let screenSizes = createSizeCurve(1.2,1.1)
    let size = window.innerWidth;

    switch (true) {
        case size >= MEDIUM_SCREEN:
            textSize = screenSizes["md"]; // Example text size for mediumScreen
            break;
        case size >= SMALL_SCREEN:
            textSize = screenSizes["sm"]; // Example text size for smallScreen
            break;
        default:
            textSize = screenSizes["default"]; // Default text size
    }
    return textSize;
}

export default theme;