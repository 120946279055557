import React, {FunctionComponent} from "react";
import Post from "../../Types/Content/Post";
import {createPostLink} from "../../Utils/LinkUtils";
import {Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PostLinks: FunctionComponent<{ story: string, nextPost: Post | undefined, previousPost: Post | undefined }> = (props) =>
{
    const theme = useTheme();
    let isXSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    let navigate = useNavigate();
    let linkHandler = (post: Post) =>
    {
        return () =>
        {
            window.scrollTo(0,0)
            navigate(createPostLink(props.story,post?.name || "invalid"))
        }
    }
    const renderPostItem = (post: Post,isNext: Boolean) => (
            <Card raised sx={{margin:1, position: 'relative'}} onClick={linkHandler(post)}>
                {isNext ?  <div style={{ position: 'absolute', top: 10, right: 10}}>
                    <ArrowForwardIcon fontSize="large" style={{ color: 'white' }}/>
                </div> : <div style={{ position: 'absolute', top: 10, left: 10}}>
                <ArrowBackIcon fontSize="large" style={{ color: 'white' }} />
                </div>}
                <CardMedia
                    component="img"
                    image={post.imageURL}
                    alt={post.altText}
                />
                <CardContent>
                    <Typography variant="h4" component="div">
                        {post.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        {"Empty"}
                    </Typography>

                </CardContent>
            </Card>
    );
    //rendering links

    if (props.nextPost === undefined && props.previousPost === undefined)
    {
        return <></>
    }
    if(! isXSmallScreen)
    {
        return <Grid container spacing={2} sx={{ pt: 2, pb: 2 }} columns={{ xs: 6, sm: 12}}>
            <Grid item xs={6}>
                {props.nextPost !== undefined &&
                    renderPostItem(props.nextPost,false)}
            </Grid>
            <Grid item xs={6}>
                {props.previousPost !== undefined &&
                    renderPostItem(props.previousPost,true)}
            </Grid>
        </Grid>
    }
    else
    {
        return (
            <Grid container spacing={2} sx={{ pt: 2, pb: 2 }} columns={{ xs: 6, sm: 12}}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                    {props.previousPost !== undefined &&
                            renderPostItem( props.previousPost,false)

                    }
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                    {props.nextPost !== undefined && renderPostItem( props.nextPost,true)
                    }
                </Grid>
            </Grid>
        );
    }






}

export default PostLinks