import * as React from 'react';
import {FunctionComponent} from 'react';
import {Navigate, useParams} from "react-router";
import StaticFileView from "./StaticFileView";
import {PAGE_NOT_FOUND_ROUTE} from "../../Constants";

/**
 * Renders a static file
 * This component mostly wraps the networking elements of the component
 * If the url param exists it renders a StaticFileView with the given file name
 * otherwise redirects to the root page
 */
const StaticFilePage: FunctionComponent = () => {
    let filename: string | undefined;
    ({filename} = useParams());
    if (filename === undefined)
    {
        //this should never actually happen
        console.error("Invalid routing shouldn't route to this page without these params");
        return <Navigate to={PAGE_NOT_FOUND_ROUTE}/>
    }
    else
    {
        return <StaticFileView fileName={filename}/>
    }
};

export default StaticFilePage