import React, {FunctionComponent, useEffect, useState} from 'react';

/**
 * Renders a loading message after 1 second. This is used to prevent the loading message from flashing on the screen.
 * @param props Props contains a optional flag if loading should be presented instantly
 * @constructor Creates a PassiveLoader component.
 */
const PassiveLoader: FunctionComponent<{ isInstantLoading?: boolean }> = (props) => {
    const [isRendered, setIsRendered] = useState(props.isInstantLoading === true);
    useEffect(() => {
        if (!isRendered)
        {
            const timeout = setTimeout(() => setIsRendered(true), 1000)
            return () => clearTimeout(timeout)
        }
    });

    if (isRendered)
    {
        return <p> loading </p>
    }
    else
    {
        return <div/>
    }
}
export default PassiveLoader