/**
 * A story grid component which list all stories and posts
 */
import * as React from 'react';
import {FunctionComponent} from 'react';
import {useQuery} from "@apollo/react-hooks";
import PassiveLoader from "../PassiveLoader";
import Story from "../../Types/Content/Story"
import {STORY_LISTING_QUERY} from "../../GraphQLQueries";
import ErrorMessage from "../ErrorMessage";
import {ContentCardData} from "../../Types/UI/ContentCardData";
import {ContentGrid} from "./ContentGrid";
import {useNavigate} from "react-router-dom";
import {createStoryLink} from "../../Utils/LinkUtils";

interface StoryList
{
    stories: Story[]
}

/**
 * A component representing a list or grid of all stories
 * @return the story grid component
 */
const StoryGrid: FunctionComponent = () =>
{
    const {loading, error, data} = useQuery<StoryList>(STORY_LISTING_QUERY);
    const navigate = useNavigate();
    if (loading)
    {
        return <PassiveLoader/>;
    } else if (error)
    {
        console.log(JSON.stringify(error))
        return <ErrorMessage isError={true}/>
    } else if (data !== undefined)
    {
        const cardData = data.stories.filter(s => s.posts.page.length > 0).map(s => new ContentCardData(
            s.name, s.posts.page[0].imageURL, s.description
            , s.posts.page[0].altText, s.id, (c) => navigate(createStoryLink(c.key))));
        return <ContentGrid ContentCardDataList={cardData}/>
    } else
    {
        return <ErrorMessage isError={true}/>
    }
};

export default StoryGrid